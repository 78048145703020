import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import VimeoVideoFrame from "../../components/custom-widgets/vimeo-video-frame";
import BestBanksDefault from "../../components/best-banks/best-banks-default";

const BestEmployer = () => {
  const SEOData = {
    title: "Best Place to Work",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Best Place to Work"
      },
      {
        name: "description",
        content:
          "What makes WaFd such a great place to work? Learn why WaFd Bank was named one of America's Best Employers."
      },
      {
        property: "og:title",
        content: "Best Place to Work"
      },
      {
        property: "og:description",
        content:
          "What makes WaFd such a great place to work? Learn why WaFd Bank was named one of America's Best Employers."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/best-employer"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-best-employer-071024.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      active: true,
      title: "Best Employer"
    }
  ];

  const forbesVideo = {
    vimeoId: "683042004",
    title: "Forbes 2022 America's Best Midsize Employers",
    class: "m-auto iframe w-100 border-radius-12"
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1 className="mb-0">Wafd Bank Named One of America's Best Employers</h1>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="text-success font-weight-semi-bold">Finding Meaning in the Workplace</h2>
              <p>
                If we've learned anything about work since 1917, it's this: people want flexibility and connection. At
                WaFd Bank, we like to do things a bit differently to attract and keep a dedicated workforce through
                competitive compensation packages, a healthy work life balance, growth and learning opportunities, and
                much more. Our people make WaFd a great place to work, learn, and grow! Read on to see how we bring
                value to our employees and why they love working for WaFd.
              </p>
            </div>
            <div className="col-lg-6">
              <VimeoVideoFrame {...forbesVideo} />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-success">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 col-lg-auto">
              <StaticImage
                src="../../images/forbes-badge.jpg"
                alt="America's Best Midsize Employers"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-md-7 col-lg">
              <h2 className="text-white">Forbes recognized WaFd Bank as one of America's Best Employers</h2>
              <Link className="text-white text-decoration-none font-weight-bold" to="/about-us/banking-careers">
                Explore WaFd Careers
                <FontAwesomeIcon className="ml-2" icon={["fal", "arrow-right"]} />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-md-6">
            <p>
              &ldquo;It has been a joy to work for such a supportive bank. The Pocatello Community holds a high level of
              importance to me. WaFd Bank has allowed me the support and opportunities to impact this great place
              (Pocatello/ Chubbuck area). WaFd Bank aligns with many of my personal values, from the Washington Federal
              Foundation grants, to supporting me in serving Pocatello as a city councilman. The support of the bank and
              my team help me to positively impact the areas in which we live and do business. Change or impact is best
              made from within the community. Thank you WaFd for your continued support!&rdquo;
              <br />
              <br />
              <span className="text-success font-weight-bold">Corey Mangum</span>
              <br />
              <span>Branch Manager, Pocatello, Idaho</span>
            </p>
            <br />
            <p className="mb-0">
              &ldquo;This is a place where you can grow your skillset and you can really do things that interest you and
              that aren't necessarily in your job description…I like to make a difference, and WaFd has given me the
              opportunity to make a difference for people around me.&rdquo;
              <br />
              <br />
              <span className="text-success font-weight-bold">Melissa Gruben</span>
              <br />
              <span>SVP of Payment Operations</span>
            </p>
            <div className="d-block d-md-none">
              <br />
              <br />
            </div>
          </div>
          <div className="col-md-6">
            <p>
              &ldquo;I recently joined the Washington Federal Foundation Board, and I feel so fortunate for the
              opportunity to be involved in giving back to the amazing non-profits within our communities. I love being
              able to support the non-profits that do so much good in our communities for our under-resourced community
              members, it is such an amazing feeling. I love working for a company that has a focus on giving back, and
              encouraging their employees to be a part of it as well.&rdquo;
              <br />
              <br />
              <span className="text-success font-weight-bold">Jen Peterson</span>
              <br />
              <span>SVP of Lending Operations</span>
            </p>
            <br />
            <p className="mb-0">
              &ldquo;It is encouraging to see folks out in their communities every month making their neighbors feel
              welcomed, supported, and knowledgeable. Whether it is financing the newest affordable housing project with
              a community land trust, preparing clients at local homeless shelters to move into permanent housing, or
              teaching high schoolers the importance of saving for a rainy day - our bankers put in the work!&rdquo;
              <br />
              <br />
              <span className="text-success font-weight-bold">MiKaela Barker</span>
              <br />
              <span>CRA and Fair Lending Analyst</span>
            </p>
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default BestEmployer;
